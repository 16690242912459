import Nav from "./modules/nav.js";

// Main JS file

function init() {
  // Nav
  Nav();
  
  // Photo gallery
  const imageControls = document.querySelector('.image__controls');
  if (imageControls) {
    const mainImage = document.querySelector('[data-gallery="image"]');
    const openLightboxButton = imageControls.querySelector('[data-action="open"]');
    openLightboxButton.addEventListener('click', () => mainImage.click());
  }
}

window.addEventListener('DOMContentLoaded', init);
