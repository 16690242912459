// Nav

const Nav = () => {
  const navToggle = document.querySelectorAll('.nav-toggle');
  const navOpenClass = 'nav-open';
  
  if (!navToggle) return;

  function handleClick() {
    document.body.classList.toggle(navOpenClass);
  }

  navToggle.forEach(toggle => toggle.addEventListener('click', handleClick));
}

export default Nav;
